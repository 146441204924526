<template>
    <el-cascader
            class="field-cascader"
            v-model="checkList"
            placeholder="请选择字段"
            :props="cascaderProps"
            :show-all-levels="false"
            :options="treeOptions"
            @change="change"
            popper-class="cas-class"
            filterable
    />
</template>

<script>
  import { GetClassFieldTree } from '@/api/system/privacy/privacy'

  export default {
    props: {
      selectIds: {
        type: Array,
      },
    },
    data() {
      return {
        checkList: [],
        cascaderProps: {
          emitPath: false,
          multiple: true,
          value: 'id',
          label: 'name',
          checkStrictly: false,
        },
        treeOptions: []
      }
    },
    created() {
      this.selectIds.forEach(s => {
        if (s != '' && this.checkList.indexOf(s) === -1) {
          this.checkList.push(s)
        }
      })
      this.getClassFieldTree()
    },
    methods: {
      getClassFieldTree() {
        GetClassFieldTree().then(res=>{
          res.data.data.forEach(r=>{
            if(r.children){
              this.treeOptions.push(r)
            }
          })
        })
      },
      change() {
        this.$emit('update:selectIds', this.checkList)
      }
    }
  }
</script>

<style>
   .field-cascader{
        width: 100% !important;
    }
</style>

<template>
  <el-dialog
    :title="!dataForm.id ? `新增${mappingObj[dataForm.mappingType]}活动` : `修改${mappingObj[dataForm.mappingType]}活动`"
    :visible.sync="visible"
    width="620px"
    :append-to-body="false"
    :modal-append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="dialogClose"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      class="assest-save-form"
      :rules="dataRule"
      label-width="100px"
    >
      <el-form-item
        label="活动名称"
        prop="name"
      >
        <el-input
          v-model="dataForm.name"
          placeholder="活动名称"
        />
      </el-form-item>
      <el-form-item
        v-if="dataForm.mappingType == 5 "
        label="使用的对象"
        prop="associationType"
        :rules="[ { required: true, message: '使用的对象的对象不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="dataForm.associationType"
          class="mr10"
          @change="useShareType"
        >
          <el-option
            v-for="(item,index) in [{name:'IT资产',id:1},{name:'部门',id:2}]"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="dataForm.mappingType === 1 || dataForm.mappingType === 2 || dataForm.mappingType === 3 || (dataForm.mappingType === 5 && dataForm.associationType === 1) || dataForm.mappingType == 6"
        label="IT资产"
        class="form-item-flex"
        prop="associationId"
        :rules="[ { required: true, message: '资产不能为空', trigger: 'change' }]"
      >
        <el-cascader
          v-model="dataForm.assetType"
          :options="assetsTypeList"
          popper-class="cas-class"
          style="width: 50%;"
          placeholder="资产类型"
          :show-all-levels="false"
          :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'label' }"
          @change="changeAssetsType"
        />
        <el-select
          v-model="dataForm.associationId"
          class="ml10"
          placeholder="请选择IT资产"
        >
          <el-option
            v-for="(item,index) in assetsList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <i
          class="el-icon-circle-plus-outline"
          style="position: relative;top: 6px;left: 10px"
          @click="addAssetsDialog"
        />
      </el-form-item>
      <el-form-item
        v-if="dataForm.mappingType == 4 "
        label="共享目的对象"
        class="form-item-flex"
        prop="associationId"
        :rules="[ { required: true, message: '共享目的对象不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="dataForm.associationType"
          class="mr10"
          @change="changeShareType"
        >
          <el-option
            v-for="(item,index) in [{name:'业务系统',id:3},{name:'第三方',id:4}]"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-select
          v-model="dataForm.associationId"
          placeholder="请选择共享目的对象"
        >
          <el-option
            v-for="(item,index) in shareDataList"
            :key="index"
            :label="item.label || item.name"
            :value="item.id"
          />
        </el-select>
        <!--        <i-->
        <!--          class="el-icon-circle-plus-outline"-->
        <!--          style="position: relative;top: 6px;left: 10px"-->
        <!--          @click="addShareDialog"-->
        <!--        />-->
      </el-form-item>
      <el-form-item
        v-if="dataForm.mappingType === 5 && dataForm.associationType === 2 "
        label="使用部门"
        prop="associationId"
        :rules="[ { required: true, message: '使用部门不能为空', trigger: 'change' }]"
      >
        <el-cascader
          v-model="dataForm.associationId"
          :options="deptList"
          placeholder="请选择使用部门"
          popper-class="cas-class"
          :show-all-levels="false"
          :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'label' }"
        />
      </el-form-item>
      <el-form-item
        label="说明"
        class="label-line-height-inherit"
        prop="description"
      >
        <el-input
          v-model="dataForm.description"
          type="textarea"
          :autosize="autosize"
          aria-placeholder="说明"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="!visible"
        @click="saveActivity"
      >
        提 交
      </el-button>
    </div>
    <AssestSaveDialog
      ref="assestSaveDialog"
      @handleFilter="queryAssets"
    />
  </el-dialog>
</template>

<script>

import debounce from 'lodash/debounce'
import { queryActivityInfo } from '@/api/dataMap/dataMapProcess'
import {
  GetAllAssetsTypeList,
  GetThirdPartySelect,
  QueryAssetsOptionsByType,
} from '@/api/enterprise/assest'
import { queryDeptOptions } from '@/api/system/system'
import { getAllProduct, queryProductSelect } from '@/api/enterprise/product'
import AssestSaveDialog from '@/views/dataIntelligent/itAssest/AssestSaveDialog.vue'

export default {
  components: { AssestSaveDialog },
  data() {
    return {
      visible: false,
      mappingObj: {
        1: '采集',
        2: '处理',
        3: '存储',
        4: '共享',
        5: '使用',
        6: '删除',
      },
      dataForm: {
        id: 0,
        dataAcquisitionId: 0,
        name: '',
        description: '',
        associationType: undefined,
        associationId: undefined,
        mappingType: 0,
        assetType: 0,
        productId: 0,
      },
      deptList: [],
      assetsTypeList: [],
      assetsList: [],
      shareDataList: [],
      dataRule: {
        name: [
          { required: true, message: '活动名称不能为空', trigger: 'change' },
        ],
        description: [
          { max: 100, message: '长度在100个字符以内', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    dialogClose() {
      this.dataForm = {
        id: 0,
        dataAcquisitionId: 0,
        name: '',
        description: '',
        associationType: undefined,
        associationId: undefined,
        mappingType: 0,
        assetType: 0,
        productId: 0,
      }
      this.assetsList = []
      this.$refs.dataForm.resetFields()
    },
    init(dataAcquisitionId, id, mappingType) {
      this.visible = true
      this.dataForm.id = id
      this.$nextTick(() => {
        this.dataForm.mappingType = mappingType
        this.dataForm.dataAcquisitionId = dataAcquisitionId
        if (this.dataForm.id) {
          this.getInfo()
        }
        if (mappingType === 1 || mappingType === 2 || mappingType === 3 || mappingType === 6) {
          this.queryAssetType()
          // this.queryAssets()
          this.dataForm.associationType = 1
        }
        if (mappingType === 4) {
          if (this.dataForm.associationType === 3) {
            this.getAllProduct()
          } else {
            this.getThirdPartyOptions()
          }
        }
        if (mappingType === 5 && this.dataForm.associationType === 2) {
          this.queryDept()
        }
        if (mappingType === 5 && this.dataForm.associationType === 1) {
          this.queryAssetType()
          this.queryAssets()
        }
      })
    },
    queryAssetType() {
      // 查询资产类型
      GetAllAssetsTypeList().then(res => {
        this.assetsTypeList = res.data.data
      })
    },
    queryProduct() {
      // 查询业务下拉
      queryProductSelect().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.shareDataList = resData.data
        }
      })
    },
    getThirdPartyOptions() {
      GetThirdPartySelect().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.shareDataList = resData.data
        }
      })
    },
    addAssetsDialog() {
      // 添加资产弹框
      this.$refs.assestSaveDialog.dialogVisible = true
    },
    addShareDialog() {
      // 填写共享对象弹框
    },
    queryAssets() {
      // 查询资产
      QueryAssetsOptionsByType({ assetsType: this.dataForm.assetType }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assetsList = resData.data
        }
      })
    },
    getAllProduct() {
      getAllProduct()
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.shareDataList = resData.data
          }
        })
    },
    changeAssetsType() {
      this.dataForm.associationId = undefined
      this.dataForm.associationType = 1
      this.queryAssets()
    },
    changeShareType() {
      this.dataForm.associationId = ''
      if (this.dataForm.associationType === 3) {
        this.getAllProduct()
      } else if (this.dataForm.associationType === 4) {
        this.getThirdPartyOptions()
      }
    },
    useShareType() {
      this.dataForm.associationId = ''
      if (this.dataForm.associationType === 1) {
        this.queryAssetType()
      } else if (this.dataForm.associationType === 2) {
        this.queryDept()
      }
    },
    queryDept() {
      // 查询部门
      queryDeptOptions().then(res => {
        this.deptList = res.data.data
      })
    },
    saveActivity: debounce(function () {
      this.$refs.dataForm.validate(valid => {
        if (!valid) {
          return false
        }
        this.$http.post(!this.dataForm.id ? '/data/mapping/activity/create' : '/data/mapping/activity/update', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.visible = false
            this.$emit('refreshDataList', this.dataForm.mappingType)
          }
        }).catch(() => {})
        return true
      })
    }, 1000, { leading: true, trailing: false }),
    // 获取信息
    getInfo() {
      queryActivityInfo(this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data,
        }
        if (this.dataForm.associationType === 3) {
          this.getAllProduct()
        } else if (this.dataForm.associationType === 4) {
          this.getThirdPartyOptions()
        } else if (this.dataForm.associationType === 1) {
          this.queryAssetType()
          this.queryAssets()
        } else if (this.dataForm.associationType === 2) {
          this.queryDept()
        }
      }).catch(() => {})
    },
  },
}
</script>

<style scoped>

</style>

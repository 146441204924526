<template>
  <div class="dataActivityFill">
    <b-card>
      <b-row>
        <b-col
          lg="8"
          sm="12"
        >
          <el-descriptions>
            <el-descriptions-item label="标题">
              {{ info.title }}
            </el-descriptions-item>
            <el-descriptions-item label="业务">
              {{ info.productName }}
            </el-descriptions-item>
            <el-descriptions-item label="参与人员">
              {{ info.participants }}
            </el-descriptions-item>
            <el-descriptions-item label="描述">
              {{ info.description }}
            </el-descriptions-item>
          </el-descriptions>
        </b-col>
        <b-col
          lg="4"
          md="12"
        >
          <div
            class="flex-end text-center"
            style="align-items: center"
          >
            <el-button
              v-if="s!==3 && !isEmpty"
              size="small"
              type="primary"
              @click="finishFillData"
            >
              完成数据映射
            </el-button>
            <el-button
              v-if="s!==3 && !isEmpty"
              size="small"
              type="primary"
              @click="saveQuestionData()"
            >
              保 存
            </el-button>
            <el-button @click="back">
              返 回
            </el-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row
      class="match-height"
      :style="{height: pageH - 180 + 'px'}"
    >
      <b-col
        xl="3"
        md="3"
      >
        <b-card>
          <VuePerfectScrollbar :style="{maxHeight: pageH - 180 + 'px'}">
            <div
              v-for="(activityType,k) in activityTypeMap"
              :key="k"
              class="fill-type-item"
            >
              <div
                class="fill-type-header flex-start"
                :class="writeDataType === activityType.type ? 'active-type' : ''"
              >
                <h4 class="mr10">
                  {{ activityType.name }}
                </h4>
                <i
                  v-if="s!==3"
                  class="el-icon-circle-plus-outline"
                  @click="activityEditDialogShow(0,activityType.type)"
                />
              </div>
              <ul class="fill-ul">
                <li
                  v-for="(item,index) in activityType.activityList"
                  :key="index"
                  :class="writeDataActivity === item.activityId ? 'fill-active' : ''"
                  @click="queryActivityData(index,item)"
                >
                  <span class="mr10 overflow-ellipsis">{{ item.activityName }} </span>
                  <div
                    v-if="s!==3"
                    class="operate-btn flex-start"
                  >
                    <i
                      class="el-icon-edit mr10"
                      @click.stop="activityEditDialogShow(item.activityId,item.activityType)"
                    />
                    <i
                      class="el-icon-delete"
                      @click.stop="dataMappingDel(item,index)"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </VuePerfectScrollbar>
        </b-card>
      </b-col>
      <b-col
        xl="9"
        md="9"
      >
        <b-card v-loading="loading">
          <VuePerfectScrollbar :style="{maxHeight: pageH - 180 + 'px'}">
            <activity_question_data
              v-show="activityTypeMap[writeDataType - 1].activityList.length > 0"
              ref="activityQuestionData"
              @refreshDataList="updateSubmitFlag"
            />
            <el-empty
              v-show="activityTypeMap[writeDataType -1].activityList.length === 0"
              description="暂无数据"
            />
          </VuePerfectScrollbar>
        </b-card>
      </b-col>
    </b-row>
    <ActivityEditDialog
      ref="editDialog"
      @refreshDataList="refreshDataList"
    />

  </div>

</template>

<script>
// eslint-disable-next-line import/extensions
import ActivityEditDialog from '@/views/dataIntelligent/dataActivity/activity_edit_dialog'
// eslint-disable-next-line camelcase
import activity_question_data from '@/views/dataIntelligent/dataActivity/activity_question_data.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// eslint-disable-next-line import/no-cycle
import {
  DeleteDataActivity, FinishDataMapping, GetMappingWriterData,
} from '@/api/dataMap/dataMapProcess'
// eslint-disable-next-line import/no-cycle
import { error, success } from '@core/utils/utils'

export default {
  components: {
    ActivityEditDialog,
    VuePerfectScrollbar,
    activity_question_data,
  },
  data() {
    return {
      // 左边是新增还是修改
      isAddAcquisition: true,
      questionVisible: false,
      submitFlag: false,
      activityTypeMap: [
        { type: 1, name: '数据采集', activityList: [] },
        { type: 2, name: '数据处理', activityList: [] },
        { type: 3, name: '数据存储', activityList: [] },
        { type: 4, name: '数据共享', activityList: [] },
        { type: 5, name: '数据使用', activityList: [] },
        { type: 6, name: '数据删除', activityList: [] },
      ],
      dataAcquisitionId: Number(this.$route.query.id),
      writeDataType: 1,
      writeDataActivity: 0,
      info: {
        title: '',
        productName: '',
        participants: '',
        description: '',
      },
      loading: false,
      isEmpty: true,
      s: Number(this.$route.query.s),
      questionList: [],
    }
  },
  created() {
    this.queryActivity()
  },
  mounted() {
    this.navActiveInit(1, '个人信息映射', '数据智能')
  },
  beforeDestroy() {
    sessionStorage.setItem('saveFlag', true)
  },
  destroyed() {
    this.navActiveInit(2, '个人信息映射', '数据智能', '/dataActivity')
  },
  methods: {
    // 新增活动后刷新左边
    refreshDataList(mappingType) {
      this.activityTypeMap = [
        { type: 1, name: '数据采集', activityList: [] },
        { type: 2, name: '数据处理', activityList: [] },
        { type: 3, name: '数据存储', activityList: [] },
        { type: 4, name: '数据共享', activityList: [] },
        { type: 5, name: '数据使用', activityList: [] },
        { type: 6, name: '数据删除', activityList: [] },
      ]
      // 查询活动信息
      GetMappingWriterData(this.dataAcquisitionId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.isEmpty = false
          resData.data.activityVOList.forEach(item => {
            this.activityTypeMap[item.activityType - 1].activityList.push(item)
          })
          // 新增活动
          if (this.isAddAcquisition) {
            this.writeDataType = mappingType
            // 当前活动取最后一个
            const lastIndex = this.activityTypeMap[mappingType - 1].activityList.length - 1
            this.writeDataActivity = this.activityTypeMap[mappingType - 1].activityList[lastIndex].activityId
          }
          this.getActivityData()
        }
      })
    },
    queryActivity() {
      this.activityTypeMap = [
        { type: 1, name: '数据采集', activityList: [] },
        { type: 2, name: '数据处理', activityList: [] },
        { type: 3, name: '数据存储', activityList: [] },
        { type: 4, name: '数据共享', activityList: [] },
        { type: 5, name: '数据使用', activityList: [] },
        { type: 6, name: '数据删除', activityList: [] },
      ]
      // 查询活动信息
      GetMappingWriterData(this.dataAcquisitionId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.info.title = resData.data.title
          this.info.productName = resData.data.productName
          this.info.sceneName = resData.data.sceneName
          this.info.participants = resData.data.participants
          this.info.description = resData.data.description
          let reFlag = true
          // 没数据
          if (!resData.data.activityVOList.length > 0) {
            this.writeDataType = 1
            this.writeDataActivity = 0
            this.isEmpty = true
          } else {
            this.isEmpty = false
          }
          resData.data.activityVOList.forEach(item => {
            if (item.activityId === this.writeDataActivity) {
              reFlag = false
            }
            this.activityTypeMap[item.activityType - 1].activityList.push(item)
          })
          if (reFlag && resData.data.activityVOList.length > 0) {
            const firstActivityVo = resData.data.activityVOList[0]
            this.writeDataType = firstActivityVo.activityType
            this.writeDataActivity = firstActivityVo.activityId
          }
          if (this.writeDataActivity) {
            this.getActivityData()
          }
        }
      })
    },
    activityEditDialogShow(activityId, activityType) {
      this.isAddAcquisition = activityId === 0
      // 显示活动编辑框
      this.$refs.editDialog.init(this.dataAcquisitionId, activityId, activityType)
    },
    queryActivityData(index, item) {
      // 查询活动详细数据
      if (this.s !== 3) {
        const hasChange = JSON.stringify(this.$refs.activityQuestionData.dataForm) !== this.$refs.activityQuestionData.oldDataForm
        if (item.activityId !== this.writeDataActivity && !this.submitFlag && hasChange) {
          this.$confirm('数据未进行保存，进行切换会丢失数据，是否进行切换?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false,
          }).then(() => {
            // 显示活动数据
            this.writeDataType = item.activityType
            this.writeDataActivity = item.activityId
            this.getActivityData()
          }).catch(() => {})
        } else {
          // 显示活动数据
          this.writeDataType = item.activityType
          this.writeDataActivity = item.activityId
          this.getActivityData()
        }
      } else {
        this.writeDataType = item.activityType
        this.writeDataActivity = item.activityId
        this.getActivityData()
      }
    },
    dataMappingDel(item) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteDataActivity(item.activityId).then(res => {
          if (res.data.code === 0) {
            success(res.data.msg)
            this.queryActivity()
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    back() {
      this.$router.push({ path: '/dataActivity' })
    },
    finishFillData() {
      this.$confirm('是否确认完成数据映射?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.loading = true
        FinishDataMapping({ id: this.dataAcquisitionId }).then(res => {
          this.loading = false
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.back()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getActivityData() {
      // 显示活动编辑框
      this.$refs.activityQuestionData.init(this.dataAcquisitionId, this.writeDataActivity)
      this.submitFlag = false
    },
    saveQuestionData() {
      this.$refs.activityQuestionData.submitForm()
    },
    updateSubmitFlag() {
      this.submitFlag = true
    },
  },

}
</script>

<style lang="scss">
.fill-type-header {
  h4 {
    margin-bottom: 0;
  }

  .el-icon-circle-plus-outline {
    color: #0d40b2;
    font-size: 20px;
    cursor: pointer;
  }
}

.active-type {
  h4 {
    color: #0d40b2;
  }
}

.fill-ul {
  padding: 10px 0;

  li {
    padding: 0 20px;
    list-style: none;
    height: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px dotted #0d40b2;
    /*margin-top: 10px;*/
    i {
      display: none;
      font-size: 20px;
    }
  }

  li:hover {
    i {
      display: block;
    }
  }

  li.fill-active {
    /*border-left: 2px solid #0d40b2;*/
    background: #0d40b2;
    color: #fff;
    border-radius: 4px;
  }
}

.dataActivityFill {
  .el-icon-circle-plus-outline {
    cursor: pointer;
    font-size: 20px;
    color: #0d40b2;
  }

  .question-form {
    padding: 0 20px;

    .el-form-item {
      margin-bottom: 10px !important;
    }

    .el-form--label-top .el-form-item__label {
      padding: 0 !important;
    }

    .el-form-item__content,
    .el-form-item__label {
      line-height: inherit !important;
    }

    .el-checkbox__label {
      font-size: 12px !important;
    }
  }

  .ps__rail-x {
    display: none !important;
  }
}
</style>

<template>
  <el-dialog
    title="数据来源"
    :visible.sync="dialogVisible"
    width="800px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="dataForm"
      :model="dataForm"
      class="assest-save-form"
      label-width="120px"
      :rules="dataRule"
    >
      <el-form-item
        label="数据来源"
        class="form-item-flex"
        prop="sourceId"
      >
        <el-cascader
          v-model="dataForm.sourceType"
          :options="assetsTypeOption"
          style="width: 50%;"
          placeholder="资产类型"
          popper-class="cas-class"
          :show-all-levels="false"
          :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'label' }"
          @change="queryAssetsOptions(1)"
        />
        <el-select
          v-model="dataForm.sourceId"
          class="ml10"
          placeholder="请选择IT资产"
          clearable
          @change="getSourceFiledList(2)"
        >
          <el-option
            v-for="(item,index) in allAssetsList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        class="label-line-height-inherit form-item-flex"
        label="来源的数据字段"
        prop="fieldIds"
      >
        <el-select
          class="mr10"
          v-model="dataForm.fieldIds"
          placeholder="请选择采集的数据字段"
          multiple
        >
          <el-option
            v-for="(item,index) in sourceFiledList"
            :key="index+'ss'"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-button  type="primary" @click="dataForm.fieldIds = []">清除</el-button>
      </el-form-item>
      <el-form-item label="数据的传输方式">
        <el-checkbox-group
          v-model="dataForm.transmissionWay"
          class="flex-group"
        >
          <el-checkbox label="内网传输" />
          <el-checkbox label="公网传输" />
          <el-checkbox label="跨地域传输" />
          <el-checkbox label="跨境传输" />
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        v-if="dataForm.transmissionWay.indexOf('公网传输')!=-1 || dataForm.transmissionWay.indexOf('跨地域传输')!=-1 || dataForm.transmissionWay.indexOf('跨境传输')!=-1"
        class="label-line-height-inherit"
        label="采用何种传输线路"
        prop="transmissionRoute"
      >
        <el-input
          v-model="dataForm.transmissionRoute"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
        class="label-line-height-inherit"
        label="数据传输是否加密?"
      >
        <el-radio-group v-model="dataForm.isEncryption">
          <el-radio
            v-for="(item,index) in yseNoOptions"
            :key="index"
            :label="item.value"
          >{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="dataForm.isEncryption"
        label="加密技术手段"
      >
        <el-input
          v-model="dataForm.encryptionMethod"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import {
  selectFiledBySource,
} from '@/api/dataMap/dataMapProcess'
import { GetAllAssetsTypeList, QueryAssetsOptionsByType } from '@/api/enterprise/assest'

export default {
  props: {
    activityDataSourceList: {
      type: Array,
    },
    tableActiveIndex: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      sourceFiledList: [],
      yseNoOptions: [
        { label: '是', value: true },
        { label: '否', value: false },
      ],
      dataMappingId: 0,
      dataForm: {
        id: 0,
        encryptionMethod: '',
        transmissionRoute: '',
        transmissionWay: [],
        isEncryption: '',
        sourceName: '',
        sourceType: undefined,
        sourceId: undefined,
        fieldIds: [],
        fieldNames: '',
        assetType: 0,
      },
      allAssetsList: [],
      assetsTypeOption: [],
      saveType: 1,
    }
  },
  computed: {
    dataRule() {
      return {
        sourceId: [
          { required: true, message: '数据来源不能为空。', trigger: 'blur' },
        ],
        transmissionRoute: [
          { required: true, message: '采用何种传输线路不能为空。', trigger: 'blur' },
        ],
        fieldIds: [
          { required: true, message: '字段不能为空。', trigger: 'blur' },
        ],
      }
    },
  },
  methods: {
    dialogOpen() {
      this.getAllAssetsTypeList()
    },
    init(mappingId, saveType, data) {
      this.$nextTick(() => {
        this.saveType = saveType
        this.dataMappingId = mappingId
        this.sourceFiledList = []
        this.getAllAssetsTypeList()
        if (saveType === 2) {
          this.dataForm = { ...data }
          if (this.dataForm.transmissionWay && typeof (this.dataForm.transmissionWay.length) !== Array) {
            this.dataForm.transmissionWay = this.dataForm.transmissionWay.split(',')
          } else {
            this.dataForm.transmissionWay = []
          }
          if (this.dataForm.sourceType) {
            this.queryAssetsOptions()
          }
          if (this.dataForm.sourceId) {
            this.getSourceFiledList()
          }
        }
      })
    },
    queryAssetsOptions(typeFlag) {
      QueryAssetsOptionsByType({ assetsType: this.dataForm.sourceType }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.allAssetsList = resData.data
          if (typeFlag === 1) {
            this.dataForm.sourceId = undefined
            this.dataForm.fieldIds = []
          }
        }
      })
    },
    getAllAssetsTypeList() {
      GetAllAssetsTypeList().then(res => {
        this.assetsTypeOption = res.data.data
      })
    },
    dialogClose() {
      this.dialogVisible = false
      this.dataForm = {
        id: 0,
        dataMappingId: 0,
        encryptionMethod: '',
        transmissionRoute: '',
        transmissionWay: [],
        isEncryption: '',
        sourceName: '',
        sourceType: undefined,
        sourceId: undefined,
        fieldIds: [],
        fieldNames: '',
        assetType: 0,
      }
      this.allAssetsList = []
    },
    getSourceFiledList(type) {
      selectFiledBySource(this.dataMappingId, this.dataForm.sourceId, 1).then(res => {
        if (res.data.code === 0) {
          this.sourceFiledList = res.data.data
          if (type === 2) {
            this.dataForm.fieldIds = []
            this.sourceFiledList.forEach(f => {
              this.dataForm.fieldIds.push(f.id)
            })
          }
        }
      })
    },
    submitForm() {
      if ((this.dataForm.transmissionWay.indexOf('公网传输') !== -1 || this.dataForm.transmissionWay.indexOf('跨地域传输') !== -1 || this.dataForm.transmissionWay.indexOf('跨境传输') !== -1) && this.dataForm.transmissionRoute.trim() === '') {
        this.dataForm.transmissionRoute = ''
      }
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          const fieldNameList = []
          this.sourceFiledList.forEach(f => {
            this.dataForm.fieldIds.forEach(id => {
              if (id === f.id) {
                fieldNameList.push(f.name)
              }
            })
          })
          this.dataForm.fieldNames = fieldNameList.join(',')
          this.allAssetsList.forEach(s => {
            if (s.id === this.dataForm.sourceId) {
              this.dataForm.sourceName = s.name || s.title
            }
          })
          const newRow = {
            id: this.dataForm.id,
            sourceName: this.dataForm.sourceName,
            sourceId: this.dataForm.sourceId,
            sourceType: this.dataForm.sourceType,
            fieldNames: this.dataForm.fieldNames,
            fieldIds: this.dataForm.fieldIds,
            transmissionWay: this.dataForm.transmissionWay.length > 0 ? this.dataForm.transmissionWay.toString() : '',
            isEncryption: this.dataForm.isEncryption,
            encryptionMethod: this.dataForm.isEncryption ? this.dataForm.encryptionMethod : '',
            transmissionRoute: this.dataForm.transmissionRoute,
          }
          const activityDataSource = this.activityDataSourceList.find(item => item.sourceId === newRow.sourceId)

          debugger
          if (this.saveType === 1) {
            if (activityDataSource !== undefined) {
              this.$message.error('来源已经存在，无法添加。')
            } else {
              this.activityDataSourceList.push(newRow)
              this.dialogVisible = false
            }
          } else if (activityDataSource && activityDataSource.id !== newRow.id) {
            this.$message.error('来源已经存在，无法添加。')
          } else {
            this.activityDataSourceList.splice(this.tableActiveIndex, 1, newRow)
            this.dialogVisible = false
          }
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}

.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>

<template>
  <div>
    <el-form
      ref="dataForm"
      v-loading="loading"
      class="question-form"
      :label-position="'top'"
      :model="dataForm"
      :disabled="s===3"
      :rules="rules"
    >
      <div
        v-for="(item,index) in questionList"
        :key="index"
      >
        <el-form-item
          v-if="item.isCanShow"
          :key="item.code"
          :class="item.questionStyle === 8 ? 'form-item-flex' : ''"
          :label="item.title"
        >
          <el-radio-group
            v-if="item.questionStyle === 1"
            v-model="item.answer"
            @change="setShowQuestion(item)"
          >
            <el-radio
              v-for="(option,index) in item.optionList"
              :key="index+'ooo'"
              :label="option"
            />
          </el-radio-group>
          <el-cascader
            v-if="item.questionStyle === 2"
            v-model="item.answer"
            class="field-cascader"
            placeholder="请选择字段"
            :props="cascaderProps"
            :show-all-levels="false"
            :options="treeOptions"
            popper-class="cas-class"
            filterable
            @change="setShowQuestion(item)"
          />
          <el-input
            v-if="item.questionStyle === 3"
            v-model="item.answer"
            type="textarea"
          />
          <el-checkbox-group
            v-if="item.questionStyle === 4"
            v-model="item.answer"
            @change="setShowQuestion(item)"
          >
            <el-checkbox
              v-for="(item1,index) in item.optionList"
              :key="index+'oo'"
              :label="item1"
            />
          </el-checkbox-group>
          <el-select
            v-if="item.questionStyle === 5"
            v-model="item.answer"
            multiple
            @change="setShowQuestion(item)"
          >
            <div v-if="item.optionType === 1">
              <el-option
                v-for="(option,index) in item.optionList"
                :key="index+'o'"
                :label="option.label"
                :value="option.id"
              />
            </div>
            <div v-else-if="item.optionType === 2">
              <el-option
                v-for="(option,index) in productOptions"
                :key="index+'p'"
                :label="option.label"
                :value="option.id"
              />
            </div>
            <div v-else-if="item.optionType === 3">
              <el-option
                v-for="(option,i) in thirdPartyOptions"
                :key="i+'t'"
                :label="option.label"
                :value="option.id"
              />
            </div>
            <div v-else-if="item.optionType === 5">
              <el-option
                v-for="(option,i) in fieldOptions"
                :key="i+'t'"
                :label="option.name"
                :value="option.id"
              />
            </div>
          </el-select>
          <div v-if="item.questionStyle === 6">
            <i
              v-if="s!==3"
              class="el-icon-circle-plus-outline"
              style="top:-30px"
              @click="sdkSaveDialogShow(1)"
            />
            <el-table
              :data="dataForm.thirdSdkList"
              border
            >
              <el-table-column
                prop="sdkName"
                :show-overflow-tooltip="true"
                label="集成SDK或插件名称"
              />
              <el-table-column
                prop="thirdPartyName"
                :show-overflow-tooltip="true"
                label="第三方名称"
              />
              <el-table-column
                prop="fieldNames"
                :show-overflow-tooltip="true"
                label="数据字段"
              />
              <el-table-column
                prop="description"
                :show-overflow-tooltip="true"
                label="目的或用途"
              />
              <el-table-column
                v-if="s!==3"
                label="操作"
                width="120px"
              >
                <template slot-scope="row">
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="sdkSaveDialogShow(2,row)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="delSdk(row)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="item.questionStyle === 7">
            <i
              v-if="s!==3"
              class="el-icon-circle-plus-outline"
              style="left: 90px;top: -30px"
              @click="dataSourceDialog(1)"
            />
            <el-table
              :data="dataForm.activityDataSourceList"
              border
            >
              <el-table-column
                prop="sourceName"
                :show-overflow-tooltip="true"
                label="采集端"
              />
              <el-table-column
                prop="fieldNames"
                :show-overflow-tooltip="true"
                label="字段"
              />
              <el-table-column
                prop="transmissionWay"
                :show-overflow-tooltip="true"
                label="传输方式"
              />
              <el-table-column
                prop="transmissionRoute"
                :show-overflow-tooltip="true"
                label="传输线路"
              />
              <el-table-column
                prop="isEncryption"
                :show-overflow-tooltip="true"
                label="是否加密"
              >
                <template slot-scope="{row}">
                  {{ row.isEncryption == 1 ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column
                prop="encryptionMethod"
                :show-overflow-tooltip="true"
                label="加密技术"
              />
              <el-table-column
                v-if="s!==3"
                label="操作"
                width="120px"
              >
                <template v-slot="scope">
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="dataSourceDialog(2,scope)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="delSource(scope.$index)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-cascader
            v-if="item.questionStyle === 8"
            v-model="assestType"
            :options="assetsTypeOption"
            popper-class="cas-class"
            style="width: 50%;"
            placeholder="资产类型"
            :show-all-levels="false"
            :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'label' }"
            @change="queryAssetsOptions(item)"
          />
          <el-select
            v-if="item.questionStyle === 8"
            v-model="sourceId"
            class="ml10"
            placeholder="请选择IT资产"
            @change="getSourceFiledList(item)"
          >
            <el-option
              v-for="(a,k) in allAssetsList"
              :key="k"
              :label="a.name"
              :value="a.id"
            />
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <SdkSaveDialog
      ref="sdkSaveDialog"
      :sdk-list="dataForm.thirdSdkList"
      :table-active-index.sync="tableActiveIndex"
    />
    <ThridSave ref="thridSave" />
    <DataRunSourceDialog
      ref="dataSourceDialog"
      :activity-data-source-list.sync="dataForm.activityDataSourceList"
      :table-active-index.sync="dataSourceTableActiveIndex"
    />
  </div>

</template>

<script>
import { GetAllAssetsTypeList, GetThirdPartySelect, QueryAssetsOptionsByType } from '@/api/enterprise/assest'
import SdkSaveDialog from '@/views/dataIntelligent/dataActivity/dataActivityQuestion/SdkSaveDialog.vue'
import DataRunSourceDialog from '@/views/dataIntelligent/dataActivity/dataActivityQuestion/DataRunSourceDialog.vue'
import ThridSave from '@/views/enterprise/thirdPParty/ThridSave.vue'
import { queryProductSelect } from '@/api/enterprise/product'
import {
  GetActivityData, MappingSaveData, selectFiledBySource,
} from '@/api/dataMap/dataMapProcess'
import { GetClassFieldTree } from '@/api/system/privacy/privacy'
import { error, success } from '@/@core/utils/utils'

export default {
  components: {
    SdkSaveDialog,
    ThridSave,
    DataRunSourceDialog,
  },
  data() {
    return {
      loading: false,
      sourceId: '',
      assestType: '',
      assetsTypeOption: [],
      fieldOptions: [],
      allAssetsList: [],
      rules: {},
      dataChange: false,
      questionList: [],
      mappingId: 0,
      activityType: 1,
      dataForm: {
        activityId: 0,
        questionDataList: [],
        activityDataSourceList: [],
        thirdSdkList: [],
      },
      oldDataForm: {
        activityId: 0,
        questionDataList: [],
        activityDataSourceList: [],
        thirdSdkList: [],
      },
      cascaderProps: {
        emitPath: false,
        multiple: true,
        value: 'id',
        label: 'name',
        checkStrictly: false,
      },
      treeOptions: [],
      thirdPartyOptions: [],
      productOptions: [],
      tableActiveIndex: 0,
      dataSourceTableActiveIndex: 0,
      s: Number(this.$route.query.s),
      mappingType: '',
      associationId: '',
    }
  },
  created() {
  },
  methods: {
    // 获取资产类型下拉
    getAllAssetsTypeList() {
      GetAllAssetsTypeList()
        .then(res => {
          this.assetsTypeOption = res.data.data
        })
    },
    // 根据资产类型查询资产
    queryAssetsOptions(item) {
      this.sourceId = undefined
      this.queryAssetsOptionsByType(item)
    },
    queryAssetsOptionsByType(item) {
      QueryAssetsOptionsByType({ assetsType: this.assestType }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.allAssetsList = resData.data
          if (item) {
            this.questionList.forEach(q => {
              if (q.code === item.fieldToQuestionCode) {
                // eslint-disable-next-line no-param-reassign
                q.answer = []
              }
            })
          }
        }
      })
    },
    // 根据资产获取来源字段
    getSourceFiledList(item) {
      this.queryField(this.sourceId)
      this.questionList.forEach(q => {
        if (q.code === item.fieldToQuestionCode) {
          q.answer = []
        }
      })
    },
    queryField(sourceId) {
      selectFiledBySource(this.mappingId, sourceId, 1).then(res => {
        if (res.data.code === 0) {
          this.fieldOptions = res.data.data
          this.questionList.forEach(item => {
            if (item.optionType === 5) {
              const newAnswer = []
              if (this.fieldOptions.length > 0) {
                item.answer.forEach(answer => {
                  this.fieldOptions.forEach(option => {
                    if (answer === option.id) {
                      newAnswer.push(answer)
                    }
                  })
                })
                item.answer = newAnswer
              } else {
                item.answer = []
              }
            }
          })
        }
      })
    },
    init(mappingId, activityId) {
      this.$nextTick(() => {
        this.fieldOptions = []
        this.assestType = ''
        this.sourceId = ''
        this.queryProductOptions()
        this.GetThirdPartySelect()
        this.mappingId = mappingId
        this.dataForm.activityId = activityId
        this.getActivityData()
        // 获取资产类型下拉 数据共享
        this.getAllAssetsTypeList()
      })
    },
    getActivityData() {
      this.loading = true
      GetActivityData(this.mappingId, this.dataForm.activityId).then(({ data: res }) => {
        this.loading = false
        if (res.code !== 0) {
          error(res.msg)
        } else {
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          }
          this.associationId = res.data.associationId
          this.mappingType = res.data.mappingType
          const map = new Map()
          if (this.dataForm.questionDataList.length > 0) {
            this.dataForm.questionDataList.forEach(item => {
              let { answer } = item
              if (item.answerType === 2) {
                if (item.answer.length > 0) {
                  if (item.questionStyle === 4) {
                    answer = item.answer.split(',')
                  } else {
                    answer = item.answer.split(',').map(Number)
                  }
                } else {
                  answer = []
                }
              }
              if (item.questionStyle === 8) {
                if (item.answer) {
                  this.assestType = Number(item.answer.split('-')[0])
                  this.sourceId = Number(item.answer.split('-')[1])
                  // 获取资产
                  this.queryAssetsOptionsByType()
                  // 根据资产获取字段
                  this.queryField(this.sourceId)
                }
              }
              map.set(item.code, answer)
            })
          }
          this.questionList = res.data.questionDataList
          if (this.questionList.length > 0) {
            this.questionList.forEach((item, index) => {
              const mapElement = map.get(item.code)
              if (mapElement !== undefined) {
                // eslint-disable-next-line no-param-reassign
                this.questionList[index].answer = mapElement
              }
              // 设置是否显示子题
              this.setShowQuestion(item)
            })
          }
          this.oldDataForm = JSON.stringify(this.dataForm)
          if (this.mappingType === 6) {
            this.queryField(this.associationId)
          } else {
            this.getClassFieldTree()
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
    setShowQuestion(item) {
      if (item.showQuestionOption && item.showQuestionOption.length > 0) {
        item.showQuestionOption.forEach(q => {
          const flag = q.options.some(o => item.answer.includes(o))
          this.questionList.forEach(item1 => {
            if (item1.code === q.childCode) {
              // eslint-disable-next-line no-param-reassign
              item1.isCanShow = flag
              if (item1.questionStyle === 6 && !flag) {
                this.dataForm.thirdSdkList = []
              }
              if (!flag) {
                if (item1.answerType === 2) {
                  // eslint-disable-next-line no-param-reassign
                  item1.answer = []
                } else {
                  // eslint-disable-next-line no-param-reassign
                  item1.answer = ''
                }
              }
            }
          })
        })
      }
    },
    queryProductOptions() {
      // 查询业务下拉
      queryProductSelect().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
        }
      })
    },
    GetThirdPartySelect() {
      GetThirdPartySelect().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.thirdPartyOptions = resData.data
        }
      })
    },
    sdkSaveDialogShow(type, data) {
      this.$refs.sdkSaveDialog.dialogVisible = true
      let d
      if (data !== undefined) {
        d = data.row
      }
      if (type === 2) {
        this.$refs.sdkSaveDialog.init(type, data.row)
        this.tableActiveIndex = data.$index
      } else {
        this.$refs.sdkSaveDialog.init(type, d)
      }
    },
    dataSourceDialog(type, data) {
      this.$refs.dataSourceDialog.dialogVisible = true
      const row = data ? data.row : null
      this.$refs.dataSourceDialog.init(this.mappingId, type, row)
      if (type === 2) {
        this.dataSourceTableActiveIndex = data.$index
      }
    },
    back() {
      this.$router.push({ path: '/dataActivity' })
    },
    delSdk(data) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.dataForm.thirdSdkList.splice(data.$index, 1)
      })
    },
    delSource(index) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.dataForm.activityDataSourceList.splice(index, 1)
      })
    },
    submitForm() {
      this.dataForm.questionDataList = []
      this.questionList.forEach(q => {
        let { answer } = q
        if (q.answerType === 2) {
          answer = q.answer.join(',')
        }
        // 处理根据资产类型和资产答案
        if (q.questionStyle === 8) {
          answer = `${this.assestType}-${this.sourceId}`
        }
        this.dataForm.questionDataList.push({
          code: q.code,
          answer,
          answerType: q.answerType,
        })
      })
      this.loading = true
      MappingSaveData(this.dataForm).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.$emit('refreshDataList')
        } else {
          error(resData.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getClassFieldTree() {
      GetClassFieldTree().then(res => {
        this.treeOptions = []
        res.data.data.forEach(r => {
          if (r.children) {
            this.treeOptions.push(r)
          }
        })
      })
    },
  },
}
</script>

<style>
    .question-form .el-form-item__content .el-icon-circle-plus-outline {
        position: absolute;
        left: 220px;
        top: -25px;
        cursor: pointer;
        font-size: 20px;
        color: #0d40b2;
    }
</style>

<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="1000px"
    title="添加资产"
    :append-to-body="true"
    :modal-append-to-body="false"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="120px"
    >
      <b-row
        class="mr10"
      >
        <b-col md="6">
          <el-form-item
            label="资产名称"
            prop="name"
            :rules="[ { required: true, message: '资产名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.name"
              maxlength="100"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="资产类型"
            prop="assetsType"
            :rules="[ { required: true, message: '资产类型不能为空', trigger: 'change' }]"
          >
            <el-cascader
              v-model="form.assetsType"
              class="mr10"
              :options="assetsTypeParentOptions"
              popper-class="cas-class"
              placeholder="资产类型"
              :show-all-levels="false"
              :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'label' }"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="资产描述">
            <el-input
              v-model="form.description"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <div class="el-form-more">
            <el-form-item
              label="资产负责人"
              prop="responsiblePerson"
              :rules="[ { required: true, message: '负责人不能为空', trigger: 'change' }]"
            >
              <el-input
                v-model="form.responsiblePerson"
                class="mr10"
                maxlength="20"
              />
            </el-form-item>
            <el-form-item class="no-label">
              <el-input
                v-model="form.responsiblePersonPhone"
                type="number"
                placeholder="请输入联系方式"
              >
                <el-select
                  slot="prepend"
                  v-model="form.responsiblePhoneArea "
                  :popper-class="'phone-select'"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item,index) in phoneAreaCodeOptions"
                    :key="index"
                    :label="item.value"
                    :value="item.value"
                  >
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </div>
        </b-col>
        <b-col md="6">
          <div class="el-form-more">
            <el-form-item label="安全负责人">
              <el-input
                v-model="form.safetyUser"
                class="mr10"
                maxlength="20"
              />
            </el-form-item>
            <el-form-item class="no-label">
              <el-input
                v-model="form.safetyUserPhone"
                type="number"
                placeholder="请输入联系方式"
              >
                <el-select
                  slot="prepend"
                  v-model="form.safetyPhoneArea"
                  :popper-class="'phone-select'"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item,index) in phoneAreaCodeOptions"
                    :key="index"
                    :label="item.value"
                    :value="item.value"
                  >
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </div>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="所属业务"
            prop="productIdList"
            :rules="[ { required: true, message: '所属业务不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.productIdList"
              multiple
              placeholder="请选择所属业务"
            >
              <el-option
                v-for="(item,index) in productOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="归属网域"
            prop="belongingArea"
          >
            <el-select v-model="form.belongingArea">
              <el-option
                v-for="item in belongingAreaOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

        </b-col>
        <b-col md="6">
          <!--<el-form-item-->
          <!--label="所属部门"-->
          <!--prop="deptId"-->
          <!--&gt;-->
          <!--<SelectTree-->
          <!--v-model="form.deptId"-->
          <!--:props="treeSelectProps"-->
          <!--:options="deptOptions"-->
          <!--/>-->
          <!--</el-form-item>-->
          <el-form-item
            label="所属部门"
            prop="deptId"
          >
            <SelectTreeMultiple
              v-model="form.deptIdList"
              :props="treeSelectProps"
              :options="deptOptions"
              :value.sync="form.deptIdList"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="物理位置">
            <el-cascader
              v-model="form.geographicLocation"
              placeholder="请选择物理位置"
              :props="countryProps"
              :show-all-levels="false"
              popper-class="cas-class"
              :options="countryTreeOptions"
              filterable
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="IP地址">
            <el-input v-model="form.ipAddress" />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="端口">
            <el-input
              v-model="form.port"
              type="number"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="资产环境">
            <el-select
              v-model="form.environment"
              placeholder="请选择资产环境"
            >
              <el-option
                v-for="item in environmentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="操作系统类型">
            <el-input
              v-model="form.osName"
              maxlength="100"
            />
          </el-form-item>
        </b-col>
        <b-col
          v-if="form.assetsTypeParent === 3"
          md="6"
        >
          <el-form-item
            label="数据库类型"
            prop="dbType"
          >
            <el-input
              v-model="form.dbType"
              maxlength="100"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="其他说明">
            <el-input
              v-model="form.otherDescription"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="安全保护措施">
            <el-input
              v-model="form.securityMeasures"
              type="textarea"
            />
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  success, error, treeDataTranslate,
} from '@core/utils/utils'
import {
  saveOrUpdateAssest,
  GetAllAssetsTypeList,
} from '@/api/enterprise/assest'
import { queryUserOptions } from '@/api/system/system'
import { ListDept } from '@/api/system/dept/dept'
import SelectTreeMultiple from '@core/components/select-tree-multiple/SelectTreeMultiple.vue'
import { getAllProduct } from '@/api/enterprise/product'

export default {
  components: { SelectTreeMultiple },
  data() {
    return {
      dialogVisible: false,
      countryProps: {
        emitPath: false,
      },
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'deptName', // 显示名称
        children: 'children', // 子级字段名
      },
      deptOptions: [],
      allAssestTypes: [],
      users: [],
      loading: false,
      environmentOptions: [
        { label: '企业本地', value: 1 },
        { label: '公有云', value: 2 },
        { label: '用户环境', value: 3 },
        { label: '其他', value: 4 },
      ],
      // 归属区域 1:公司内 2：公司外 3：境外
      belongingAreaOptions: [
        { label: '可信任域', value: 1 },
        { label: '不可信任域', value: 2 },
        { label: '未知', value: 3 },
      ],
      assetsTypeOption: [],
      assetsTypeParentOptions: [],
      productOptions: [],
      form: {
        phoneAreaCode: '+86',
        geographicLocation: '',
        securityMeasures: '',
        assetsType: undefined,
        assetsTypeParent: undefined,
        id: 0,
        name: '',
        ipAddress: '',
        environment: undefined,
        port: undefined,
        description: '',
        responsiblePerson: '',
        responsiblePhoneArea: '+86',
        safetyPhoneArea: '+86',
        osType: '',
        safetyUserPhone: '',
        responsiblePersonPhone: '',
        dbType: '',
        safetyUser: '',
        otherDescription: '',
        belongingArea: undefined,
        deptIdList: [],
        productIdList: [],
      },
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        phoneAreaCode: '+86',
        geographicLocation: '',
        securityMeasures: '',
        assetsType: undefined,
        assetsTypeParent: undefined,
        id: 0,
        name: '',
        ipAddress: '',
        environment: undefined,
        port: undefined,
        description: '',
        responsiblePerson: '',
        responsiblePhoneArea: '+86',
        safetyPhoneArea: '+86',
        osType: '',
        safetyUserPhone: '',
        responsiblePersonPhone: '',
        dbType: '',
        safetyUser: '',
        otherDescription: '',
        belongingArea: undefined,
        deptIdList: [],
        productIdList: [],
      }
    },
    dialogOpen() {
      this.getEnterpriseUersSelect()
      this.getAllAssetsTypeList()
      this.getAllProduct()
      this.listDept()
    },
    getAllProduct() {
      getAllProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
        }
      })
    },
    listDept() {
      ListDept().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.deptOptions = treeDataTranslate(resData.data, 'id', 'parentId')
        }
      })
    },
    getAllAssetsTypeList() {
      GetAllAssetsTypeList().then(res => {
        const resData = res.data
        this.assetsTypeParentOptions = resData.data
      })
    },
    getEnterpriseUersSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.users = res.data.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.port = Number(this.form.port)
          this.form.environment = Number(this.form.environment)
          this.form.belongingArea = Number(this.form.belongingArea)
          saveOrUpdateAssest(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              this.$emit('handleFilter')
              success(resData.msg)
              this.dialogVisible = false
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .el-form-more {
    display: flex;
    justify-content: flex-start;
    .el-form-item {
      flex: 1;
    }
    .el-input--suffix {
      width: 80px;
    }
    .el-input--suffix .el-input__inner {
      padding-right: 0px;
    }
  }
  .no-label .el-form-item__content {
    margin-left: 10px !important;
  }
  .el-range-editor.is-active,
  .el-range-editor.is-active:hover,
  .el-select .el-input.is-focus .el-input__inner {
    /*border-color: transparent !important;*/
  }
  .phone-select .el-scrollbar__wrap{
    max-height: 300px !important;
    overflow: scroll !important;
  }
</style>

<template>
  <el-dialog
    :modal="false"
    :title="saveType === 2 ? '修改第三方SDK' : '新增第三方SDK'"
    :visible.sync="dialogVisible"
    width="800px"
    :append-to-body="false"
    :modal-append-to-body="true"
    @close="dialogClose"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="140px"
    >
      <el-form-item
        label="SDK或插件名称"
        prop="sdkName"
        :rules="[ { required: true, message: 'SDK或插件名称不能为空', trigger: 'change' }]"
      >
        <el-input

          v-model="form.sdkName"
          maxlength="100"
        />
      </el-form-item>
      <el-form-item
        label="第三方"
        prop="thirdPartyId"
        class="form-item-flex"
        :rules="[ { required: true, message: '第三方不能为空', trigger: 'change' }]"
      >
        <el-select
          v-model="form.thirdPartyId"
          placeholder="请选择第三方"
        >
          <el-option
            v-for="(item,index) in thirdPartyList"
            :key="index+'tt'"
            :label="item.label"
            :value="item.id"
          />
        </el-select>
<!--        <i-->
<!--          style="top: 6px; position: relative;left: 10px;"-->
<!--          class="el-icon-circle-plus-outline"-->
<!--          @click="thridSaveDialogShow"-->
<!--        />-->
      </el-form-item>
      <el-form-item
        class="label-line-height-inherit"
        label="来源的数据字段"
      >
        <el-cascader
          ref="cascader"
          v-model="form.fieldIds"
          class="field-cascader"
          placeholder="请选择字段"
          :props="cascaderProps"
          :show-all-levels="false"
          :options="treeOptions"
          popper-class="cas-class"
          filterable
        />
      </el-form-item>
      <el-form-item
        label="目的及用途说明"
      >
        <el-input
          v-model="form.description"
          type="textarea"
          maxlength="100"
        />
      </el-form-item>

    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
    <ThridSave
      ref="thridSave"
      @handleFilter="getThirdPartyOptions"
    />
  </el-dialog>
</template>

<script>
import ThridSave from '@/views/enterprise/thirdPParty/ThridSave.vue'
import { GetThirdPartyOptionList, GetThirdPartySelect } from '@/api/enterprise/assest'
import FieldCascaderMultiple from '@core/components/cascader-multiple/FieldCascaderMultiple.vue'
import { GetClassFieldTree } from '@/api/system/privacy/privacy'

export default {
  components: {
    ThridSave,
    // eslint-disable-next-line vue/no-unused-components
    FieldCascaderMultiple,
  },
  props: {
    sdkList: {
      type: Array,
    },
    tableActiveIndex: {
      type: Number,
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        description: '',
        fieldIds: [],
        fieldNames: '',
        sdkName: '',
        thirdPartyId: undefined,
        thirdPartyName: '',
      },
      thirdPartyList: [],
      saveType: 1,
      cascaderProps: {
        emitPath: false,
        multiple: true,
        value: 'id',
        label: 'name',
        checkStrictly: false,
      },
      treeOptions: [],
    }
  },
  methods: {
    init(saveType, data) {
      this.$nextTick(() => {
        this.saveType = saveType
        this.getThirdPartyOptions()
        this.getClassFieldTree()
        if (saveType === 2) {
          this.form.fieldIds = data.fieldIds
          this.form.description = data.description
          this.form.fieldNames = data.fieldNames
          this.form.thirdPartyId = data.thirdPartyId
          this.form.thirdPartyName = data.thirdPartyName
          this.form.sdkName = data.sdkName
        }
      })
    },
    getClassFieldTree() {
      GetClassFieldTree().then(res => {
        this.treeOptions = []
        res.data.data.forEach(r => {
          if (r.children) {
            this.treeOptions.push(r)
          }
        })
      })
    },
    getThirdPartyOptions() {
      GetThirdPartySelect().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.thirdPartyList = resData.data
        }
      })
    },
    thridSaveDialogShow() {
      this.$refs.thridSave.dialogVisible = true
    },
    dialogClose() {
      this.form = {
        description: '',
        fieldIds: [],
        fieldNames: '',
        sdkName: '',
        thirdPartyId: undefined,
        thirdPartyName: '',
      }
      this.dialogVisible = false
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.sdkList && this.sdkList.length > 0) {
            const b = this.sdkList.some(item => item.sdkName === this.form.sdkName || item.thirdPartyId === this.form.thirdPartyId)
            if (b && this.saveType === 1) {
              this.$message.error('名称或第三方已经存在。')
              return
            }
          }
          this.thirdPartyList.forEach(t => {
            if (t.id === this.form.thirdPartyId) {
              this.form.thirdPartyName = t.label
            }
          })
          const checkNodes = this.$refs.cascader.getCheckedNodes(true)
          if (checkNodes.length > 0) {
            this.form.fieldNames = checkNodes.map(c => c.label).join(',')
          } else {
            this.form.fieldNames = ''
          }
          const newRow = {
            sdkName: this.form.sdkName,
            thirdPartyName: this.form.thirdPartyName,
            fieldNames: this.form.fieldNames,
            thirdPartyId: this.form.thirdPartyId,
            description: this.form.description,
            fieldIds: this.form.fieldIds,
          }
          if (this.saveType === 2) {
            this.$set(this.sdkList, this.tableActiveIndex, newRow)
          } else {
            this.sdkList.push(newRow)
          }
          this.dialogVisible = false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}

.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
